'use strict';

angular.module('cpformplastApp.items')
    .factory('ItemSchedule', function() {

    class ItemSchedule {
        constructor({id, sheetVerified, readyProd, dieOrderStock, comment, fFinition, alumBlockOrdered, mMachine, dieVerified, recycleMold, moldOut, mechanic, startDate, endDate, setupEndDate, readyProdDate}) {
          this.id = id;
          this.sheetVerified = sheetVerified;
          this.readyProd = readyProd;
          this.dieOrderStock = (dieOrderStock === true || dieOrderStock === "true") ? "ordered" : dieOrderStock;
          this.recycleMold = recycleMold;
          this.comment = comment;
          this.fFinition = fFinition;
          this.alumBlockOrdered = alumBlockOrdered;
          this.mMachine = mMachine;
          this.dieVerified = dieVerified;
          this.recycleMold = recycleMold;
          this.moldOut = moldOut;
          this.commentChanged = false;
          this.mechanic = mechanic;
          this.mechanicChanged = false;
          if (startDate) { this.startDate = new Date(startDate); }
          if (endDate) { this.endDate = new Date(endDate); }
          if (setupEndDate) { this.setupEndDate = new Date(setupEndDate); }
          if (readyProdDate) { this.readyProdDate = new Date(readyProdDate); }
        }
    }

    return ItemSchedule;
});
